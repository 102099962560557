import React, { FC } from "react";
import classNames from "clsx";

import sergiyPashenkoAvatar from "../images/team/SergiyPashenko.jpeg";
import olehKhalinAvatar from "../images/team/OlehKhalin.jpg";
import volodymyrKlykovAvatar from "../images/team/VolodymyrKlykov.jpeg";
// import ogdeerAvatar from "../images/team/ogdeer.jpeg";
import elenaAvatar from "../images/team/ElenaZh.jpg";
import valentynShapovalAvatar from "../images/team/ValentynShapoval.png";
import kravchenkoAvatar from "../images/team/IlonaKravchenko.jpg";

import { ReactComponent as TwitterIcon } from "../icons/social/twitter.svg";
import { ReactComponent as GithubIcon } from "../icons/social/github.svg";
import { ReactComponent as LinkedinCircleIcon } from "../icons/social/linkedin-circle.svg";
import { ReactComponent as BehanceCircleIcon } from "../icons/social/behance-circle.svg";
import { ReactComponent as WebsiteIcon } from "../icons/social/website.svg";
import CommonSection from "./CommonSection";

type Member = {
  name: string;
  avatar: string;
  github?: string;
  twitter?: string;
  linkedin?: string;
  behance?: string;
  website?: string;
};

const TEAM_MEMBERS: Member[] = [
  {
    name: "Sergiy Pashenko",
    avatar: sergiyPashenkoAvatar,
    github: "bitoffog",
    twitter: "bitoffog",
    // linkedin: "pas1ko",
  },
  {
    name: "Oleh Khalin",
    avatar: olehKhalinAvatar,
    github: "olehkhalin",
    twitter: "OlehKhalin",
    // linkedin: "olegkhalin",
  },
  {
    name: "Volodymyr Klykov",
    avatar: volodymyrKlykovAvatar,
    twitter: "v_klykov",
    linkedin: "vdklykov",
  },
  {
    name: "Elena Zhykharieva",
    avatar: elenaAvatar,
    behance: "elenazh99bcf5",
    // https://www.behance.net/elenazh99bcf5
  },
  {
    name: "Valentyn Shapoval",
    avatar: valentynShapovalAvatar,
    github: "phoenixVS",
    // twitter: "vs_phoenix",
  },
  {
    name: "Ilona Kravchenko",
    avatar: kravchenkoAvatar,
    website: "https://ilonakcopywrite.com/",
    linkedin: "ilonakravchenko",
  },
];

const Team: FC = () => {
  return (
    <CommonSection
      title="Our team"
      className="flex flex-col items-center"
    >
      <div
        className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3 max-w-[62rem]"
        data-aos-id-team
      >
        {TEAM_MEMBERS.map((member, i) => (
          <div
            key={i}
            className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3"
            data-aos="fade-up"
            data-aos-anchor="[data-aos-id-team]"
          >
            <div className="flex flex-col items-center">
              <img
                className="rounded-full mb-4"
                src={member.avatar}
                width="120"
                height="120"
                alt="Team member 01"
              />
              <h4 className="text-xl font-medium mb-1 text-[#BCC2DB]">
                {member.name}
              </h4>
              <div className="flex items-center -mx-1">
                {member.website && (
                  <SocialLink
                    type="website"
                    slug={member.website}
                    className="p-1"
                  />
                )}
                {member.twitter && (
                  <SocialLink
                    type="twitter"
                    slug={member.twitter}
                    className="p-1"
                  />
                )}
                {member.github && (
                  <SocialLink
                    type="github"
                    slug={member.github}
                    className="p-1"
                  />
                )}
                {member.linkedin && (
                  <SocialLink
                    type="linkedin"
                    slug={member.linkedin}
                    className="p-1"
                  />
                )}
                {member.behance && (
                  <SocialLink
                    type="behance"
                    slug={member.behance}
                    className="p-1"
                  />
                )}
              </div>
            </div>
          </div>
        ))}

        <div
          className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3"
          data-aos="fade-up"
          data-aos-anchor="[data-aos-id-team]"
        >
          <div className="flex flex-col items-center">
            <div
              className="rounded-full mb-4 bg-black/25 flex items-center justify-center font-bold text-brand-tertiary"
              style={{ width: 120, height: 120 }}
            >
              {/* <span className="flex-1 text-3xl text-right">+</span> */}
              <span className="text-3xl">+5</span>
              {/* <span className="flex-1"></span> */}
            </div>
            <h4 className="text-xl font-medium mb-1 text-[#BCC2DB]">
              Contributors
            </h4>
          </div>
        </div>
      </div>
    </CommonSection>
  );
};

export default Team;

type SocialLinkProps = {
  type: "twitter" | "github" | "linkedin" | "behance" | "website";
  slug: string;
  className?: string;
};

const SocialIcon = {
  twitter: TwitterIcon,
  github: GithubIcon,
  linkedin: LinkedinCircleIcon,
  behance: BehanceCircleIcon,
  website: WebsiteIcon,
};

const linkBase = {
  website: "",
  twitter: "https://twitter.com/",
  github: "https://github.com/",
  linkedin: "https://linkedin.com/in/",
  behance: "https://www.behance.net/",
};

const SocialLink: FC<SocialLinkProps> = ({ type, slug, className }) => {
  const Icon = SocialIcon[type];
  const href = `${linkBase[type]}${slug}`;

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        "text-brand-white hover:text-brand-accent transition ease-in-out duration-200",
        className
      )}
    >
      <Icon className="h-7 w-auto fill-current" />
    </a>
  );
};

import React, { FC } from "react";

import CommonSection from "./CommonSection";

const HeroAbout: FC = () => (
  <CommonSection isAbout className="!pt-32 !pb-[10rem] md:!pb-[16rem] md:!pt-40">
    <div className="max-w-3xl mx-auto">
      <h1 className="h1 mb-10 text-center" data-aos="fade-up">
        Let’s introduce ourselves
      </h1>

      <div className="flex flex-col md:flex-row">
        <h2
          className="mb-4 text-xl font-bold leading-[2rem] md:leading-[2.5rem] whitespace-nowrap min-w-[10rem]"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Wigwam is
        </h2>

        <div className="flex-1" />

        <p
          className="text-xl text-gray-300 leading-[2rem] md:leading-[2.5rem] mb-8"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          a distributed team of engineers and designers who are committed to
          accelerate the evolution and adoption of Web3.
        </p>
      </div>

      <div className="flex flex-col md:flex-row">
        <h2
          className="mb-4 text-xl font-bold leading-[2rem] md:leading-[2.5rem] whitespace-nowrap min-w-[10rem]"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          Our goal is
        </h2>

        <div className="flex-1" />

        <p
          className="text-xl text-gray-300 leading-[2rem] md:leading-[2.5rem] mb-8"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          to improve the experience of interacting with non-custodial software
          and decentralized applications. We want to make it more accessible for
          everyone.
        </p>
      </div>
    </div>
  </CommonSection>
);

export default HeroAbout;

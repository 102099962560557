import React, { FC } from "react";
import classNames from "clsx";
import CommonSection from "./CommonSection";

const Career: FC = () => {
  return (
    <CommonSection
      title="Join the Wigwam team"
      description={
        <span className="flex flex-col">
          <span className="mb-8 text-lg text-transparent bg-clip-text bg-gradient-to-r from-brand-accenttwo to-brand-accentone">
            Help us create true things.
          </span>

          <span>
            Are you interested?{" "}
            <span className="md:whitespace-nowrap">
              Are you looking for a career or just want to get involved in a
              project?
            </span>
            <br />
            Drop mail to{" "}
            <a href="mailto:dev@wigwam.app">
              <strong>dev@wigwam.app</strong>
            </a>
            , and we'll contact you shortly.
          </span>
        </span>
      }
    >
      <div className="max-w-2xl mx-auto">
        {/* World map */}
        <div className="relative">
          {/* People pics */}
          <img
            src={require("../images/worldmap.png").default}
            width="672"
            height="330"
            alt="World map"
          />

          {[
            {
              avatarUrl: require("../images/avatars/1.png").default,
              width: "3.5rem",
              height: "3.5rem",
              top: "22%",
              left: "52%",
              aosDelay: undefined,
            },
            {
              avatarUrl: require("../images/avatars/2.png").default,
              width: "2.5rem",
              height: "2.5rem",
              top: "50%",
              left: "58%",
              aosDelay: "400",
            },
            {
              avatarUrl: require("../images/avatars/3.png").default,
              width: "2rem",
              height: "2rem",
              top: "71%",
              left: "80%",
              aosDelay: "100",
            },
            {
              avatarUrl: require("../images/avatars/4.png").default,
              width: "2.5rem",
              height: "2.5rem",
              top: "34%",
              left: "76.5%",
              aosDelay: "700",
            },
            {
              avatarUrl: require("../images/avatars/5.png").default,
              width: "4rem",
              height: "4rem",
              top: "29.5%",
              left: "8.5%",
              aosDelay: "500",
            },
            {
              avatarUrl: require("../images/avatars/6.png").default,
              width: "2.5rem",
              height: "2.5rem",
              top: "56%",
              left: "19%",
              aosDelay: "200",
            },
            {
              avatarUrl: require("../images/avatars/7.png").default,
              width: "2.5rem",
              height: "2.5rem",
              top: "20%",
              left: "24.5%",
              aosDelay: "600",
            },
            {
              avatarUrl: require("../images/avatars/8.png").default,
              width: "2.5rem",
              height: "2.5rem",
              top: "39%",
              left: "43%",
              aosDelay: "300",
            },
          ].map(({ avatarUrl, width, height, top, left, aosDelay }, i) => (
            <div
              key={i}
              className={classNames(
                "absolute rounded-full overflow-hidden",
                "bg-black/20"
              )}
              style={{
                width,
                height,
                top,
                left,
                backgroundImage: `url('${avatarUrl}')`,
                backgroundSize: "100% 100%",
              }}
              data-aos="fade-up"
              data-aos-delay={aosDelay}
            />
          ))}
        </div>
      </div>
    </CommonSection>
  );
};

export default Career;

import React, { FC } from "react";

import Header from "../partials/Header";
import HeroAbout from "../partials/HeroAbout";
import Team from "../partials/Team";
import Career from "../partials/Career";
import Newsletter from "../partials/Newsletter";
import Footer from "../partials/Footer";
import Seo from "../partials/Seo";

const About: FC = () => {
  return (
    <>
      <Seo title="About" />

      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          {/*  Page sections */}
          <HeroAbout />
          <Team />
          <Career />
          <Newsletter />
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
};

export default About;
